import {Injectable} from "@angular/core";
import {concatMap, Observable, startWith, combineLatest, tap, of} from "rxjs";
import {filter, map} from "rxjs/operators";
import {
  AsyncData,
  AtLeastPartiallyResolvedAsyncData,
  LoadingState,
  wrapData
} from "../utils/async";
import {convertCsvToObject} from "../utils/csv";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "./auth.service";
import {environment} from "../../environments/environment";

export interface Product {
  id: string;
  barcode: string;
  barcodePackage: string;
  name: string;
  quantityAvailable: number;

  /**
   * Price for autoll
   */
  price: number;

  /**
   * Price for given customer
   */
  customerPrice: number;

  /**
   * Base selling price for the product
   * Without any discounts
   */
  baseSellPrice: number;

  vat: number;
  group: string;
}

export interface CustomerPrice {
  productId: string;
  customerId: string;
  price: number;
}

export interface GroupDiscount {
  customerId: string;
  groupId: string;
  discountPercent: number;
}

type csvHeaderValuesProducts = 'katalog'|'nazev'|'ean'|'skupina'|'baleni'|'carkbal'
  |'ncena'|'cena1'
  |'cena2'|'cena3'|'cena4'
  |'akcnicena'|'dph'|'podkod'
  |'mnozstvi'|'jednotka'
  |'podsklad'|'bodyzbozi';

type csvHeaderValuesCustomerPrices = 'zbozi'|'zakaznik'|'cena';
type csvHeaderValuesGroupPrices = 'zakaznik'|'skupina'|'sleva';

type CachedCustomerPrices = Map<string, Map<string, CustomerPrice>>;
type CachedGroupDiscounts = Map<string, Map<string, GroupDiscount>>;

interface Cache {
  products: Product[];
  customerPrices: CachedCustomerPrices;
  groupDiscounts: CachedGroupDiscounts;
  date: Date;
  cacheValidTo: Date;
}

@Injectable({
  providedIn: "root"
})
export class ProductService {
  static INTERNAL_GROUP = '1';

  private products: Product[] = [
    { id: '1', barcode: '8596295000082', barcodePackage: '8596295000082',
      name: 'Stírací los', quantityAvailable: 1,
      price: 100,
      baseSellPrice: 105,
      customerPrice: 110, vat: 21, group: 'losy' }
  ];

  private _cache: Cache = {
    products: [],
    customerPrices: new Map(),
    groupDiscounts: new Map(),
    date: new Date(),
    cacheValidTo: new Date(),
  };

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  public loadPrices(): Observable<AsyncData<{products: Product[], prices: CachedCustomerPrices, discounts: CachedGroupDiscounts}>> {
    return combineLatest([
      this.getProductsFromCsv(),
      this.getCustomerPricesFromCsv(),
      this.getGroupDiscountsFromCsv()
    ]).pipe(
      filter(([products, prices, discounts]) => {
        return products.state == 'complete' && prices.state == 'complete' && discounts.state == 'complete';
      }),
      tap(([products, customerPrices, discounts]) => {
        if (products.state === 'complete' && customerPrices.state === 'complete' && discounts.state === 'complete') {
          this._cache = {
            products: products.data,
            customerPrices: customerPrices.data,
            groupDiscounts: discounts.data,
            date: new Date(),
            cacheValidTo: new Date(Date.now() + 5 * 60 * 60),
          };
        }
      }),
      map(([products, prices, discounts]) => {
        return wrapData({
          products: (products as AtLeastPartiallyResolvedAsyncData<Product[]>).data,
          prices: (prices as AtLeastPartiallyResolvedAsyncData<CachedCustomerPrices>).data,
          discounts: (discounts as AtLeastPartiallyResolvedAsyncData<CachedGroupDiscounts>).data,
        });
      }),
      startWith(LoadingState)
    );
  }

  private getProductsFromCsv(): Observable<AsyncData<Product[]>> {
    return this.authService.authToken$.pipe(
      concatMap(token => {
        return this.http.get(environment.server + '/products', {
          responseType: 'text',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }),
      map(res => {
        const csvArray = convertCsvToObject(res).map((item: Record<csvHeaderValuesProducts, string>): Product => ({
          id: item.katalog,
          barcode: item.ean,
          barcodePackage: item.carkbal,
          name: item.nazev,
          price: parseFloat(item.ncena),
          baseSellPrice: parseFloat(item.cena1),
          customerPrice: parseFloat(item.cena1),
          vat: parseInt(item.dph),
          quantityAvailable: parseInt(item.mnozstvi),
          group: item.skupina,
        }));

        return csvArray.filter((x: Product) => x.group != ProductService.INTERNAL_GROUP);
      }),
      map(result => wrapData(result)),
      startWith(LoadingState),
    );
  }

  private getCustomerPricesFromCsv(): Observable<AsyncData<CachedCustomerPrices>> {
    return this.authService.authToken$.pipe(
      concatMap(token => {
        return this.http.get(environment.server + '/customer-prices', {
          responseType: 'text',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }),
      map(res => {
        const csvArray = convertCsvToObject(res).map((item: Record<csvHeaderValuesCustomerPrices, string>) => ({
          productId: item.zbozi,
          customerId: item.zakaznik,
          price: parseFloat(item.cena),
        }));

        return csvArray.reduce((map: Map<string, Map<string, CustomerPrice>>, price: CustomerPrice) => {
          if (!map.has(price.customerId)) {
            map.set(price.customerId, new Map());
          }

          map.get(price?.customerId ?? '')?.set(price.productId, price);
          return map;
        }, new Map());
      }),
      map(result => wrapData(result)),
      startWith(LoadingState),
    );
  }

  private getGroupDiscountsFromCsv(): Observable<AsyncData<CachedGroupDiscounts>> {
    return this.authService.authToken$.pipe(
      concatMap(token => {
        return this.http.get(environment.server + '/group-discounts', {
          responseType: 'text',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }),
      map(res => {
        const csvArray = convertCsvToObject(res).map((item: Record<csvHeaderValuesGroupPrices, string>) => ({
          groupId: item.skupina,
          customerId: item.zakaznik,
          discountPercent: parseFloat(item.sleva),
        }));

        return csvArray.reduce((map: Map<string, Map<string, GroupDiscount>>, price: GroupDiscount) => {
          if (!map.has(price.customerId)) {
            map.set(price.customerId, new Map());
          }

          map.get(price?.customerId ?? '')?.set(price.groupId, price);
          return map;
        }, new Map());
      }),
      map(result => wrapData(result)),
      startWith(LoadingState),
    );
  }

  getDataFromCache(key: keyof Cache) {
    if (this.isCacheValid()) {
      return this._cache[key];
    }

    return null;
  }

  isCacheValid() {
    return this._cache.cacheValidTo > new Date();
  }

  private calculateCustomerPriceForProduct(productCode: string, customerId: string|null, products: Product[],
                                           customerPrices: CachedCustomerPrices, groupDiscounts: CachedGroupDiscounts
  ): Product|null {
    const product = products.find(x => this.productMatchesCodeOrId(x, productCode)) ?? null;

    return product != null
      ? this._calculateCustomerPriceForProduct(product, customerId, customerPrices, groupDiscounts)
      : null;
  }

  private _calculateCustomerPriceForProduct(product: Product, customerId: string|null,
                                           customerPrices: CachedCustomerPrices, groupDiscounts: CachedGroupDiscounts
  ): Product|null {
    if (product == null) {
      return null;
    }

    const customerPricesMap = customerPrices.get(customerId ?? '') ?? new Map();
    const customerPrice = customerPricesMap.get(product.id);

    const groupDiscountsArr = groupDiscounts.get(customerId ?? '') ?? new Map();
    const groupDiscount = groupDiscountsArr.get(product.group);

    if (customerPrice?.price != null) {
      return { ...product, customerPrice: customerPrice?.price };
    }

    const priceBeforeDiscount = product.customerPrice;
    const discountPct = (groupDiscount?.discountPercent ?? 0) / 100;
    const finalPrice = priceBeforeDiscount * (1 - discountPct);

    return { ...product, customerPrice: finalPrice };
  }


  private getProductByCodeWithCustomerPrice(productCode: string, customerId: string|null): Observable<AsyncData<Product|null>> {
    return of(this.isCacheValid()).pipe(
      concatMap(isCacheValid => {
        if (isCacheValid) {
          return of(wrapData({
            products: this.getDataFromCache('products') as Product[],
            prices: this.getDataFromCache('customerPrices') as CachedCustomerPrices,
            discounts: this.getDataFromCache('groupDiscounts') as CachedGroupDiscounts,
          }));
        } else {
          return this.loadPrices();
        }
      }),
      filter(asyncData => asyncData.state === 'complete'),
      map((asyncData) => {
        if (asyncData.state === 'complete') {
          const product = this.calculateCustomerPriceForProduct(
            productCode, customerId, asyncData.data.products, asyncData.data.prices, asyncData.data.discounts
          );

          return wrapData(product);
        }

        return LoadingState;
      }),
      startWith(LoadingState),
    );
  }

  private productMatchesCodeOrId(product: Product, code: string) {
    return product.barcode == code
      || product.barcodePackage === code
      || product.id.toUpperCase() === code.toUpperCase();
  }

  public getProductByCode(code: string, customerId: string|null = null): Observable<AsyncData<Product|null>> {
    return this.getProductByCodeWithCustomerPrice(code, customerId);
  }

  public searchProducts(searchString: string, customerId: string|null): Observable<AsyncData<Product[]>> {
    return of(this.isCacheValid()).pipe(
      concatMap(isCacheValid => {
        if (isCacheValid) {
          return of(wrapData({
            products: this.getDataFromCache('products') as Product[],
            prices: this.getDataFromCache('customerPrices') as CachedCustomerPrices,
            discounts: this.getDataFromCache('groupDiscounts') as CachedGroupDiscounts,
          }));
        } else {
          return this.loadPrices();
        }
      }),
      filter(asyncData => asyncData.state === 'complete'),
      map((asyncData) => {
        if (asyncData.state === 'complete') {
          const regex = new RegExp(searchString, 'i');
          const filteredProducts = asyncData.data.products.filter(x => {
            return this.productMatchesCodeOrId(x, searchString) || x.name.match(regex);
          }).map(x => this._calculateCustomerPriceForProduct(x, customerId, asyncData.data.prices, asyncData.data.discounts)!);

          return wrapData(filteredProducts);
        }

        return LoadingState;
      }),
      startWith(LoadingState),
    );
  }
}
