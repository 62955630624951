<ng-container *ngIf="products$ | async as products">
  <ng-container *ngIf="products.state !== 'complete'">
    <h2 mat-dialog-title>Přidání zboží do košíku</h2>
    <mat-dialog-content class="mat-typography">
      <ng-container *ngIf="products.state === 'loading'">Načítám produkt...</ng-container>
      <ng-container *ngIf="products.state === 'error'">Chyba: {{products.error}}</ng-container>
    </mat-dialog-content>
  </ng-container>
  <ng-container *ngIf="products.state === 'complete'">
    <ng-container *ngIf="products.data == null; else productView">
      Produkt nenalezen!
    </ng-container>
    <ng-template #productView>
      <ng-container *ngIf="selectedProduct != null; else showSelection">
        <autollshop-barcode-add-product-to-cart
          [product]="selectedProduct"
        ></autollshop-barcode-add-product-to-cart>
      </ng-container>
      <ng-template #showSelection>
        <h2 mat-dialog-title>Přidání zboží do košíku</h2>
        <mat-dialog-content class="mat-typography">
          <autollshop-barcode-product-selection
            [products]="products.data!"
            [(selectedProduct)]="selectedProduct"
          ></autollshop-barcode-product-selection>
        </mat-dialog-content>
        <mat-dialog-actions align="end">
          <button mat-raised-button [mat-dialog-close]="null">Storno</button>
        </mat-dialog-actions>

      </ng-template>
    </ng-template>
  </ng-container>
</ng-container>
