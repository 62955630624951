import {AfterViewInit, ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Html5Qrcode, Html5QrcodeSupportedFormats} from 'html5-qrcode';
import {CameraDevice, QrcodeErrorCallback, QrcodeSuccessCallback} from "html5-qrcode/core";
import {Html5QrcodeFullConfig} from "html5-qrcode/esm/html5-qrcode";
import {FormBuilder, Validators} from "@angular/forms";
import {ProductService} from "../services/product.service";
import {
  AddProductToCartComponent,
  ProductDialogData
} from "../add-product-to-cart/add-product-to-cart.component";
import {MatDialog} from "@angular/material/dialog";
import {OrderService, ProductInCart} from "../services/order.service";
import {concatMap, Subject, takeUntil} from "rxjs";
import {Router} from "@angular/router";
import {MainRoutes} from "../routes";
import {ProductSelectionComponent} from "../product-selection/product-selection.component";
import {
  AsyncAddProductDialogComponent,
  AsyncAddProductDialogData
} from "../async-add-product-dialog/async-add-product-dialog.component";

@Component({
  selector: 'autollshop-barcode-scan-code',
  templateUrl: './scan-code.component.html',
  styleUrls: ['./scan-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScanCodeComponent implements OnInit, OnDestroy, AfterViewInit {
  Routes = MainRoutes;

  cameras: CameraDevice[] = [];
  selectedCameraId?: string;

  reader!: Html5Qrcode;

  isScannerRunning: boolean = false;

  form = this.fb.nonNullable.group({
    code: this.fb.nonNullable.control('', Validators.required),
  });

  note: string = '';

  productsInCart$ = this.orderService.cart$;
  customer$ = this.orderService.customer$;

  destroy$ = new Subject();

  showBuyPrice: boolean = false;
  constructor(
    private fb: FormBuilder,
    private productService: ProductService,
    private dialogService: MatDialog,
    private orderService: OrderService,
    private router: Router
  ) {
  }

  createQrCodeReader() {
    const config: Html5QrcodeFullConfig = {
      verbose: false,
      formatsToSupport: [
        // These 2 codes are most probably the correct ones:
        Html5QrcodeSupportedFormats.EAN_13,
        Html5QrcodeSupportedFormats.EAN_8,

        Html5QrcodeSupportedFormats.UPC_A,
        Html5QrcodeSupportedFormats.UPC_E,
        Html5QrcodeSupportedFormats.UPC_EAN_EXTENSION,

        Html5QrcodeSupportedFormats.CODABAR,
        Html5QrcodeSupportedFormats.CODE_39,
        Html5QrcodeSupportedFormats.CODE_93,
        Html5QrcodeSupportedFormats.CODE_128,
        Html5QrcodeSupportedFormats.ITF,

        Html5QrcodeSupportedFormats.RSS_14,
        Html5QrcodeSupportedFormats.RSS_EXPANDED,
      ],
      experimentalFeatures: {
        useBarCodeDetectorIfSupported: true,
      },
      useBarCodeDetectorIfSupported: true,
    };
    this.reader = new Html5Qrcode('reader', config);
  }

  ngOnInit(): void {
    this.loadCameras();

    this.orderService.order$.pipe(
      takeUntil(this.destroy$),
    ).subscribe(order => {
      this.note = order?.note ?? '';
      if (order == null) {
        this.orderService.newOrder();
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.createQrCodeReader();
  }

  async loadCameras() {
    try {
      this.cameras = await Html5Qrcode.getCameras();
    } catch (error) {
      console.error(error);
    }
  }

  switchCamera() {
    this.reader.stop();
    this.startScanning();
  }

  async startScanning() {
    const cameraSettings = this.selectedCameraId ?? { facingMode: 'environment' };

    // aspect ratio of BAR code is 1.469" : 1.02"
    const otherSettings = { fps: 10, qrbox: {
        width: 250/1.5,
        height: 174/1.5
      },
      disableFlip: true };
    const success: QrcodeSuccessCallback = (result) => this.findProductByCode(result);
    const error: QrcodeErrorCallback = (error) => this.onError(error);

    this.isScannerRunning = true;
    await this.reader.start(cameraSettings, otherSettings, success, error);
  }

  findProductByCode(code: string) {
    this.testErrorHandling(code);
    this.form.patchValue({ code: code });

    const getProduct$ = this.orderService.customer$.pipe(
      concatMap(customer => this.productService.searchProducts(code, customer?.id ?? null))
    );

    const dialogData: AsyncAddProductDialogData = {
      type: 'add',
      products$: getProduct$
    };
    const dialog = this.dialogService.open<AsyncAddProductDialogComponent, AsyncAddProductDialogData>(AsyncAddProductDialogComponent, {
      data: dialogData
    });

    dialog.afterClosed().subscribe(product => {
      if (product != null) {
        this.orderService.addProduct(product);
      }
    });

    if (this.isScannerRunning) {
      this.stopScanning();
    }
  }

  /**
   * For testing in production.0
   * This method throws exception for certain special codes.
   * @param code
   */
  testErrorHandling(code: string) {
    if (code.match(/###TEST###/)) {
      if (code.match(/EXCEPTION/)) {
        throw new Error('Testing error handling');
      }
    }
  }

  onError(error: string) {
    console.log(error);
  }

  async stopScanning() {
    if (this.isScannerRunning) {
      this.isScannerRunning = false;
      await this.reader.stop();
    }
  }

  editProduct(product: ProductInCart) {
    const dialogData: ProductDialogData = {
      type: 'edit',
      productInCart: product,
    };
    const dialog = this.dialogService.open<AddProductToCartComponent, ProductDialogData>(AddProductToCartComponent, {
      data: dialogData
    });

    dialog.afterClosed().subscribe(updatedProduct => {
      if (updatedProduct != null) {
        this.orderService.updateProduct(product, updatedProduct);
      }
    });
  }

  removeProduct(product: ProductInCart) {
    this.orderService.removeProduct(product);
  }

  submitOrder() {
    this.orderService.setNote(this.note);
    this.router.navigate([MainRoutes.OrderRecapitulation]);
  }
}
