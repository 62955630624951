import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {wrapData} from "../utils/async";
import {Product} from "../services/product.service";
import {MatSelectionListChange} from "@angular/material/list";

@Component({
  selector: 'autollshop-barcode-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSelectionComponent {

  @Input()
  products: Product[] = [];

  @Input()
  selectedProduct: Product|null = null;

  @Output()
  selectedProductChange = new EventEmitter<Product>();

  makeSelection(event: MatSelectionListChange) {
    if (event.options.length === 1) {
      this.selectedProduct = event.options[0].value;
      this.selectedProductChange.next(this.selectedProduct!);
    }
  }
}
