import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, Renderer2} from '@angular/core';
import {Order, OrderService} from "../services/order.service";
import {MatDialog} from "@angular/material/dialog";
import {ConfirmationDialogComponent} from "../confirmation-dialog/confirmation-dialog.component";
import {InfoDialogData} from "../info-dialog/info-dialog.component";
import {Overlay} from "@angular/cdk/overlay";
import {DOCUMENT} from "@angular/common";
import {Router} from "@angular/router";
import {MainRoutes} from "../routes";
import {DialogRef} from "@angular/cdk/dialog";

@Component({
  selector: 'autollshop-barcode-saved-orders',
  templateUrl: './saved-orders.component.html',
  styleUrls: ['./saved-orders.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SavedOrdersComponent implements OnInit {
  public orders: Order[] = [];
  public displayedColumns = ['id', 'actions'];

  constructor(
    private readonly orderService: OrderService,
    private readonly dialogService: MatDialog,
    private readonly dialogRef: DialogRef,
    private readonly overlay: Overlay,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private cd: ChangeDetectorRef,
    private router: Router,
  ) {
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'dialog-open-disable-scrolling');
  }

  ngOnInit() {
    this.renderer.addClass(this.document.body, 'dialog-open-disable-scrolling');
    this.loadOrders();
  }

  loadOrders() {
    const ordersFromStorage = this.orderService.getOrdersFromLocalStorage();
    this.orders = Object.keys(ordersFromStorage)
      .map(x => ordersFromStorage[x]);
    this.orders.sort((x, y) => y.date.getTime() - x.date.getTime());
  }

  sendOrder(order: Order) {
    const data: InfoDialogData = {
      title: 'Potvrďte odeslání objednávky',
      content: 'Odeslat objednávku na server?'
    };
    const dialog = this.dialogService.open(ConfirmationDialogComponent, {
      data,
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
    dialog.afterClosed().subscribe(resend => {
      if (resend) {
        this.orderService.sendOrderToServer(order, false);
      }
    });
  }

  editOrder(order: Order) {
    const data: InfoDialogData = {
      title: 'Potvrďte editaci objednávky',
      content: 'Upravit objednávku?'
    };
    const dialog = this.dialogService.open(ConfirmationDialogComponent, {
      data,
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
    dialog.afterClosed().subscribe(resend => {
      if (resend) {
        this.orderService.editOrder(order);
        this.dialogRef.close();
        this.router.navigate([MainRoutes.ScanCode]);
      }
    });
  }

  deleteOrder(order: Order) {
    const data: InfoDialogData = {
      title: 'Potvrďte smazání objednávky',
      content: 'Smazat objednávku z lokální paměti? (Nedojde k odstranění objednávky ze serveru)'
    };
    const dialog = this.dialogService.open(ConfirmationDialogComponent, {
      data,
      scrollStrategy: this.overlay.scrollStrategies.noop()
    });
    dialog.afterClosed().subscribe(confirmDeletion => {
      if (confirmDeletion) {
        this.orderService.deleteOrderFromLocalStorage(order);
        this.loadOrders();
        this.cd.detectChanges();
      }
    });
  }
}
