import {ChangeDetectionStrategy, Component, Inject, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface InfoDialogData {
  title: string;
  content: string;
}

@Component({
  selector: 'autollshop-barcode-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InfoDialogComponent {
  title: string;
  content: string;

  constructor(@Inject(MAT_DIALOG_DATA) @Optional() private data: InfoDialogData) {
    this.title = data.title;
    this.content = data.content;
  }
}
