import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Optional,
  Output
} from '@angular/core';
import {Product} from "../services/product.service";
import {ProductInCart} from "../services/order.service";
import {AbstractControl, FormBuilder, FormControl, ValidatorFn, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";

export interface BaseProductDialogData {
  type: 'add'|'edit'
}

export interface EditProductDialogData extends BaseProductDialogData {
  type: 'edit'
  productInCart: ProductInCart;
}

export interface AddProductDialogData extends BaseProductDialogData {
  type: 'add'
  product: Product|null
}

export type ProductDialogData = AddProductDialogData | EditProductDialogData;

@Component({
  selector: 'autollshop-barcode-add-product-to-cart',
  templateUrl: './add-product-to-cart.component.html',
  styleUrls: ['./add-product-to-cart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddProductToCartComponent implements OnInit {
  @Input()
  product: Product|null = null;

  @Input()
  type: 'add'|'edit' = 'add';

  @Input()
  productInCart: Partial<ProductInCart> = {};

  @Output()
  productInCartChange = new EventEmitter<Partial<ProductInCart>>();

  @Input()
  showBuyPrice: boolean = false;

  public form = this.fb.nonNullable.group({
    product: new FormControl<Product|null>(null),
    price: new FormControl<number>(0, [Validators.required]),
    quantity: new FormControl<number>(1, [Validators.required]),
    note: new FormControl<string>('', []),
  });

  constructor(@Inject(MAT_DIALOG_DATA) @Optional() private data: ProductDialogData, private fb: FormBuilder) {
  }

  patchFormValues(product: Product|null, productInCart?: ProductInCart) {
    const countValidator: ValidatorFn = (x: AbstractControl) => {
      if (x.value <= 0) {
        return {
          moreThenAllowed: 'Invalid amount'
        };
      }

      return null;
    };

    this.form.get('quantity')?.addValidators(countValidator);
    const patchValue = {
      price: product?.customerPrice,
      quantity: 1,
      ...{ ...productInCart ?? { product } },
    }

    this.form.patchValue(patchValue);
    this.form.updateValueAndValidity();
  }

  ngOnInit(): void {
    this.productInCart = {
      quantity: 1,
    };

    const dataSource = this.product != null
      ? { type: this.type, product: this.product, productInCart: this.productInCart }
      : this.data;

    if (dataSource.type === 'add') {
      this.product = dataSource.product;
      this.patchFormValues(dataSource.product);
    } else {
      this.product = dataSource.productInCart.product!;
      this.patchFormValues(dataSource.productInCart.product!, dataSource.productInCart as any);
    }
  }

  get margin() {
    const revenue = (this.form.value?.price ?? 0) - (this.product?.price ?? 0);
    const margin = revenue / (this.form.value?.price ?? 1);

    const formatter = new Intl.NumberFormat('cs-CZ', {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });

    return formatter.format(margin);
  }
}
