import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {calculateCartPrice, ProductInCart} from "../services/order.service";

@Component({
  selector: 'app-order-items-table',
  templateUrl: './order-items-table.component.html',
  styleUrls: ['./order-items-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemsTableComponent implements OnInit {

  @Input()
  editable = true;

  @Input()
  cartItems: ProductInCart[] = [];

  @Output()
  editItem = new EventEmitter<ProductInCart>();

  @Output()
  removeItem = new EventEmitter<ProductInCart>();

  displayedColumns = [
    'name', 'quantity', 'buyPrice', 'price', 'total', 'actions'
  ];

  footerColumns = [
    'orderTotalDesc', 'emptyFooter', 'emptyFooter', 'emptyFooter', 'orderTotal', 'emptyFooter'
  ];

  @Input()
  showBuyPrice: boolean = false;

  get total() {
    const items = this.cartItems ?? [];

    return calculateCartPrice(items);
  }

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = [
      'name', 'quantity', 'buyPrice', 'price', 'total',
      ...(this.editable ? [ 'actions' ] : ['note']),
    ];
  }
}
