<mat-card appearance="outlined">
  <mat-card-title>Rekapitulace objednávky</mat-card-title>
  <mat-card-content>
    <ng-container *ngIf="data$ | async as data">
      <p>Zákazník: <strong>{{ data?.customer?.name ?? '' }}</strong></p>

      <app-order-items-table
        [cartItems]="data.cart"
        [editable]="false"
      >
      </app-order-items-table>

      <em>Poznámka</em>
      <p>{{ data?.order?.note ?? '' }}</p>
    </ng-container>

    <div class="action-buttons">
      <a mat-raised-button
        [disabled]="(orderStatus$ | async)?.loading"
        [routerLink]="[ '/' +  Routes.ScanCode]"
      >Upravit</a>

      <button mat-raised-button color="primary"
              [disabled]="disableSubmitButton$ | async"
              (click)="submitOrder()"
      >Odeslat</button>
  </div>
  </mat-card-content>
</mat-card>
