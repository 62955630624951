import {parse, stringify} from "@klinki/csv";
import {calculateOrderPrice, calculateProductPrice, Order} from "../services/order.service";

export function convertCsvToObject(data: string, typed: boolean = false) {
  return parse(data, { typed }).reduce((result, curr, index, arr) => {
    if (index !== 0) {
      const value = curr.reduce((resObject: any, header: string, idx: number) => ({
        ...resObject,
        [arr[0][idx]]: curr[idx]
      }), {});
      result.push(value);
    }

    return result;
  }, []);
}

const emptyFields = (count: number): string[] => Array(count).fill('');

function sanitizeString(str: string) {
  return str?.replaceAll(';', ',')
    ?.replaceAll('\r', '')
    ?.replaceAll('\n', ' ')
  ?? '';
}

export function convertOrderToCsv(order: Order) {
  const fileHeaderFields = [
    'Prijato',
    'CisloDokladu',
    'CisloZakaznika',
    'KatCislo',
    'Nazev',
    'Cena',
    'CenaSDPH',
    'SazbaDPH',
    'Pocet',
    'Jednotka',
    'CenaA',
    'CenaB',
    'CenaC',
    'CenaD',
    'Poznamka',
    'Expedovano',
    'ZpusobPlatby',
    'ZpusobDopravy',
    'DanovyDoklad',
    'MnozstevniSleva',
    'DoplnkovaSleva',
    'Postovne',
    'Balne',
    'CenaCelkemBezDPH',
    'CenaCelkemSDHP',
    'IN_VObjCis',
    'IN_Stav',
    'PolozkaID',
    'RodicovskaPolozkaID'
  ];

  const orderTotal = calculateOrderPrice(order);
  const orderTotalWithVat = calculateOrderPrice(order, true);

  const orderStatus = 0;

  const orderValues = [
    order.date.toLocaleDateString('cs-CZ').replaceAll(' ', ''),
    order.id,
    order.customer.id,
    ...emptyFields(11), // these will be cart items on other rows
    sanitizeString(order.note),
    ...emptyFields(4),
    0, // MnozstevniSleva
    '', // DoplnkovaSleva
    0, // Postovne
    '', // Balne
    orderTotal.toFixed(2),
    orderTotalWithVat.toFixed(2),
    order.shortId, // IN_VObjCis
    orderStatus, // IN_Stav
    '', // PolozkaID
    '' // RodicovskaPolozkaID
  ];

  const productsValues = order.products.map(productInCart => {
    const product = productInCart.product;

    return [
      ...emptyFields(3), // order-related empty values: (Prijato, CisloDokladu, CisloZakaznika)
      product.id,
      sanitizeString(product.name),
      productInCart.price.toFixed(2),
      (productInCart.price * (1 + (product.vat / 100))).toFixed(2),
      product.vat,
      productInCart.quantity,
      1, // Jednotka
      ...emptyFields(4), // CenaA-D
      sanitizeString(productInCart.note),
      ...emptyFields(4), // order-related empty values: Expedovano, ZpusobPlatby, ZpusobDopravy, DanovyDoklad
      ...emptyFields(4), // order-related empty values: MnozstevniSleva, DoplnkovaSleva, Postovne, Balne
      '', // order-related empty value: CenaCelkemBezDPH
      calculateProductPrice(productInCart, true).toFixed(2), // CenaCelkemSDPH,
      order.shortId, // IN_VObjCis
      orderStatus, // IN_Stav
      0, // PolozkaID
      '', // RodicovskaPolozkaID
    ];
  });

  const exportData = [
    fileHeaderFields,
    orderValues,
    ...productsValues,
  ];

  return stringify(exportData, { delimiter: ';' })
    .replaceAll("\n", "\r\n");
}
