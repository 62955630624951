import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {OrderService} from "../services/order.service";
import {Router} from "@angular/router";
import {MainRoutes} from "../routes";

@Component({
  selector: 'autollshop-barcode-order-completed',
  templateUrl: './order-submitted.component.html',
  styleUrls: ['./order-submitted.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderSubmittedComponent implements OnInit {
  constructor(public orderService: OrderService, private router: Router) {}

  ngOnInit(): void {}

  newOrder() {
    this.orderService.selectCustomerAndNewOrder();
    this.router.navigate([MainRoutes.SelectCustomer]);
  }
}
