import { Component, HostListener } from '@angular/core';
import {versions} from "../environments/versions";

@Component({
  selector: 'autollshop-barcode-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  host: {
    '[attr.app-version]': 'appVersion.version',
    '[attr.app-commit]': 'appVersion.revision',
    '[attr.app-build-date]': 'appVersion.date',
    '[attr.app-branch]': 'appVersion.branch',
  }
})
export class AppComponent {
  readonly appVersion = versions;

  title = 'autollshop-barcode';


  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event: any) {
    // Cancel the event
    event.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
    // Chrome requires returnValue to be set
    event.returnValue = '';
  }
}
