import {ChangeDetectionStrategy, Component, Inject} from '@angular/core';
import {Observable, of, takeWhile, tap} from "rxjs";
import {AsyncData, wrapData} from "../utils/async";
import {Product} from "../services/product.service";
import {ProductInCart} from "../services/order.service";
import {AbstractControl, FormBuilder, FormControl, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {BaseProductDialogData} from "../add-product-to-cart/add-product-to-cart.component";

export interface AsyncAddProductDialogData extends BaseProductDialogData {
  type: 'add';
  products$: Observable<AsyncData<Product[]|null>>;
}

@Component({
  selector: 'autollshop-barcode-async-add-product-dialog',
  templateUrl: './async-add-product-dialog.component.html',
  styleUrls: ['./async-add-product-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsyncAddProductDialogComponent {
  products$: Observable<AsyncData<Product[]|null>>;

  selectedProduct: Product|null = null;

  constructor(@Inject(MAT_DIALOG_DATA) private data: AsyncAddProductDialogData, private fb: FormBuilder) {
    this.products$ = data.products$.pipe(
      tap(data => {
        if (data.state === 'complete' && data.data?.length === 1) {
          this.selectedProduct = data.data[0];
        }
      })
    );
  }

  ngOnInit(): void {
  }
}
