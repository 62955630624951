  <table mat-table [dataSource]="cartItems">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> Název </th>
      <td mat-cell *matCellDef="let element">
        {{ element.product.id }} {{ element.product?.name }}
      </td>
    </ng-container>

    <!-- Quantity Column -->
    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef> Počet </th>
      <td mat-cell *matCellDef="let element"> {{ element.quantity }} </td>
    </ng-container>

    <!-- Buy Price Column -->
    <ng-container matColumnDef="buyPrice">
      <th mat-header-cell *matHeaderCellDef> Nák. </th>
      <td mat-cell *matCellDef="let element"> {{ showBuyPrice ? element.product?.price?.toFixed(2) : '' }} </td>
    </ng-container>

    <!-- Price Column -->
    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef> Cena </th>
      <td mat-cell *matCellDef="let element"> {{element.price.toFixed(2)}} </td>
    </ng-container>

    <!-- Total price Column -->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> Celkem </th>
      <td mat-cell *matCellDef="let element"> {{ (element.price * element.quantity).toFixed(2) }} </td>
    </ng-container>

    <!-- Note Column -->
    <ng-container matColumnDef="note">
      <th mat-header-cell *matHeaderCellDef> Pozn. </th>
      <td mat-cell *matCellDef="let element"> {{ element.note }} </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu"
                [matMenuTriggerData]="{ product: element }">
          <mat-icon>more_vert</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="emptyFooter">
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="orderTotalDesc">
      <td mat-footer-cell *matFooterCellDef>Celková cena</td>
    </ng-container>

    <ng-container matColumnDef="orderTotal">
      <td mat-footer-cell [attr.colspan]="displayedColumns.length" *matFooterCellDef>{{total.toFixed(2)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">
        Objednávka je prázdná
      </td>
    </tr>
    <tr mat-footer-row *matFooterRowDef="footerColumns">
      <td>{{total.toFixed(2)}}</td>
    </tr>
  </table>



<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-product="product">
    <button mat-menu-item (click)="editItem.emit(product)">
      <mat-icon>edit</mat-icon>
      <span>Upravit</span>
    </button>
    <button mat-menu-item (click)="removeItem.emit(product)">
      <mat-icon>delete</mat-icon>
      <span>Odstranit</span>
    </button>
  </ng-template>
</mat-menu>
