import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular-ivy';

import { AppComponent } from './app.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { SelectCustomerComponent } from './select-customer/select-customer.component';
import { ScanCodeComponent } from './scan-code/scan-code.component';
import { AppRouterModule } from './app.routing';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatInputModule } from '@angular/material/input';
// import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
// import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
// import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatSelectModule } from '@angular/material/select';
import { AddProductToCartComponent } from './add-product-to-cart/add-product-to-cart.component';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule } from '@angular/common/http';
// import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { MtxSelectModule } from '@ng-matero/extensions/select';
import { MatTableModule } from '@angular/material/table';
import { OrderItemsTableComponent } from './order-items-table/order-items-table.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { OrderSubmittedComponent } from './order-completed/order-submitted.component';
import { OrderRecapitulationComponent } from './order-recapitulation/order-recapitulation.component';
import { ProductSelectionComponent } from './product-selection/product-selection.component';
import { AsyncAddProductDialogComponent } from './async-add-product-dialog/async-add-product-dialog.component';
import { MatListModule } from '@angular/material/list';
import { Router } from '@angular/router';
import { SavedOrdersComponent } from './saved-orders/saved-orders.component';
import { InfoDialogComponent } from './info-dialog/info-dialog.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginFormComponent,
    SelectCustomerComponent,
    ScanCodeComponent,
    AddProductToCartComponent,
    OrderItemsTableComponent,
    OrderSubmittedComponent,
    OrderRecapitulationComponent,
    ProductSelectionComponent,
    AsyncAddProductDialogComponent,
    SavedOrdersComponent,
    InfoDialogComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRouterModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    FormsModule,
    MatDialogModule,
    HttpClientModule,
    MatAutocompleteModule,
    NgSelectModule,
    MtxSelectModule,
    MatTableModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatListModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: true,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
