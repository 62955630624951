<mat-card appearance="outlined">
  <mat-card-title>Login</mat-card-title>
  <mat-card-content>
    <form [formGroup]="form" (ngSubmit)="login()">
      <div>
      <mat-form-field appearance="fill">
        <mat-label>Username</mat-label>
        <input matInput formControlName="username" />
      </mat-form-field>
      </div>
      <div>
      <mat-form-field appearance="fill">
        <mat-label>Password</mat-label>
        <input matInput [type]="showPassword ? 'text' : 'password'" formControlName="password" />
        <mat-icon matSuffix (click)="togglePasswordVisibility()">visibility</mat-icon>
      </mat-form-field>
      </div>

      <strong *ngIf="loginStatus">
        {{ loginStatus }}
      </strong>
      <button mat-raised-button color="primary">Login</button>
    </form>
  </mat-card-content>
</mat-card>
