import {Router, RouterModule, Routes} from "@angular/router";
import {ScanCodeComponent} from "./scan-code/scan-code.component";
import {LoginFormComponent} from "./login-form/login-form.component";
import {SelectCustomerComponent} from "./select-customer/select-customer.component";
import {MainRoutes} from "./routes";
import {inject} from "@angular/core";
import {AuthService} from "./services/auth.service";
import {first, map} from "rxjs/operators";
import {OrderService} from "./services/order.service";
import {OrderSubmittedComponent} from "./order-completed/order-submitted.component";
import {OrderRecapitulationComponent} from "./order-recapitulation/order-recapitulation.component";

const authGuard = () => {
  const router = inject(Router);
  return inject(AuthService).isLoggedIn$.pipe(
    map(isLoggedIn => isLoggedIn ? true : router.parseUrl('/login')),
    first(),
  );
};

const customerSelectedGuard = () => {
  const router = inject(Router);
  return inject(OrderService).customer$.pipe(
    map(customer => customer != null ? true : router.parseUrl(MainRoutes.SelectCustomer)),
    first()
  );
};

export const routes: Routes = [
  { path: MainRoutes.Login, component: LoginFormComponent },
  {
    path: '',
    canActivateChild: [authGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: MainRoutes.SelectCustomer },
      { path: MainRoutes.SelectCustomer, component: SelectCustomerComponent },
      { path: MainRoutes.ScanCode, component: ScanCodeComponent, canActivate: [customerSelectedGuard] },
      { path: MainRoutes.OrderSubmitted, component: OrderSubmittedComponent, canActivate: [customerSelectedGuard] },
      { path: MainRoutes.OrderRecapitulation, component: OrderRecapitulationComponent, canActivate: [customerSelectedGuard] },
    ],
  },
];

export const AppRouterModule = RouterModule.forRoot(routes, {
  useHash: false,
});
