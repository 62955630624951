export interface AuthToken {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
}

export interface UserData {
  name: string;

  authToken: AuthToken;
}

export type AnonymousUser = { userType: 'Anonymous' };
export type LoggedInUser<T = UserData> = { userType: 'LoggedIn' } & T;

export type ApplicationUser<T = UserData> = AnonymousUser | LoggedInUser<T>;

export class User {
  static Anonymous: AnonymousUser = Object.freeze({ userType: 'Anonymous' });
  static LoggedIn = { userType: 'LoggedIn' };

  static logged<T = UserData>(user: T): LoggedInUser<T> {
    return {
      userType: "LoggedIn",
      ...user
    };
  }

  static anonymous(): AnonymousUser {
    return User.Anonymous;
  }
}
