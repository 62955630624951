import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, of, shareReplay, Subject, takeUntil} from "rxjs";
import {AsyncData, NotYetStartedState} from "../utils/async";
import {Customer, OrderService} from "../services/order.service";
import {Router} from "@angular/router";
import {MainRoutes} from "../routes";
import {ProductService} from "../services/product.service";
import {filter} from "rxjs/operators";
import {
  AsyncAddProductDialogComponent,
  AsyncAddProductDialogData
} from "../async-add-product-dialog/async-add-product-dialog.component";
import {Dialog} from "@angular/cdk/dialog";
import {SavedOrdersComponent} from "../saved-orders/saved-orders.component";
import {MatDialog} from "@angular/material/dialog";
import {Overlay} from "@angular/cdk/overlay";

@Component({
  selector: 'autollshop-barcode-select-customer',
  templateUrl: './select-customer.component.html',
  styleUrls: ['./select-customer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectCustomerComponent implements OnInit, OnDestroy {
  customers$: Observable<AsyncData<Customer[]>> = of(NotYetStartedState);
  prices$: Observable<AsyncData<any>> = of(NotYetStartedState);

  selectedCustomer: Customer|null = null;
  destroy$ = new Subject();

  constructor(
    private orderService: OrderService,
    private productService: ProductService,
    private router: Router,
    private dialogService: MatDialog,
    private readonly overlay: Overlay
  ) {

  }

  ngOnInit(): void {
    this.customers$ = this.orderService.getCustomers();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  next() {
    this.orderService.selectCustomer(this.selectedCustomer!);
    this.prices$ = this.productService.loadPrices().pipe(shareReplay());

    this.prices$.pipe(
      takeUntil(this.destroy$),
      filter(result => result.state === 'complete'),
    ).subscribe(result => {
      this.router.navigate([MainRoutes.ScanCode]);
    });
  }

  customerSearchMatch(term: string, customer: Customer): boolean {
    const customerData = `${customer.name} - IČO: ${customer.ico} - ${customer.city} ${customer.street} ${customer.zip}`;
    const regex = new RegExp(term, 'i');
    return customerData.match(regex) != null;
  }


  showSavedOrders() {
    const dialog = this.dialogService.open<SavedOrdersComponent>(SavedOrdersComponent, {
      scrollStrategy: this.overlay.scrollStrategies.block(),
      panelClass: 'saved-orders-dialog'
    });
  }
}
