<ng-container *ngIf="products?.length === 0; else showSelection">
  Produkt nenalezen!
</ng-container>

<ng-template #showSelection>
  <mat-selection-list dense="true" [multiple]="false" (selectionChange)="makeSelection($event)">
    <mat-list-option *ngFor="let product of products" [value]="product">
      {{ product.id }} - {{ product.name }} - {{ product.quantityAvailable }}&nbsp;ks.
    </mat-list-option>
  </mat-selection-list>
</ng-template>
