<mat-card appearance="outlined">
  <mat-card-title>Výběr zákazníka</mat-card-title>
  <mat-card-content>
    <ng-container *ngIf="customers$ | async as customers">
      <ng-container *ngIf="customers.state === 'loading'">
        Loading...
      </ng-container>
      <ng-container *ngIf="customers.state === 'complete'">
        <mat-form-field appearance="fill">
          <mat-label>Zákazník</mat-label>

          <mtx-select [items]="customers.data" [(ngModel)]="selectedCustomer" [searchFn]="customerSearchMatch">
            <ng-template ng-label-tmp ng-option-tmp let-customer="item" let-index="index">
              <div>
                <div>{{ customer.name }}</div>
                <div>IČO: {{ customer.ico }}</div>
                <div>{{ customer.city }}</div>
              </div>
            </ng-template>

            <!--
            <mtx-option *ngFor="let customer of customers.data" [value]="customer">
              {{ customer.name }} - IČO: {{ customer.ico }} - {{ customer.city }}\
              <div>
                <div>{{ customer.name }}</div>
                <div>IČO: {{ customer.ico }}</div>
                <div>{{ customer.city }}</div>
              </div>
            </mtx-option>
            -->

          </mtx-select>
        </mat-form-field>
      </ng-container>
    </ng-container>

    <button (click)="next()"
            mat-raised-button color="primary"
            [disabled]="selectedCustomer == null">Další</button>
    <ng-container *ngIf="(prices$ | async)?.state === 'loading'">
      Loading...
    </ng-container>

    <div class="action-buttons">
      <button mat-fab (click)="showSavedOrders()">
        <mat-icon>save</mat-icon>
      </button>
    </div>
  </mat-card-content>
</mat-card>
