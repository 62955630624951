<mat-card appearance="outlined">
  <mat-card-title>Skenování kódu</mat-card-title>
  <mat-card-content>
    <div id="reader"></div>

    <ng-container *ngIf="cameras.length > 1">
      <label>Foťák</label>
      <mat-select [(value)]="selectedCameraId" (valueChange)="switchCamera()">
        <mat-option *ngFor="let camera of cameras"
                    [value]="camera.id">
          {{ camera.label }}
        </mat-option>
      </mat-select>
    </ng-container>

    <button mat-raised-button color="primary" [disabled]="isScannerRunning" (click)="startScanning()">Začít skenovat</button>
    <button mat-raised-button color="warn" [disabled]="!isScannerRunning" (click)="stopScanning()">Přestat skenovat</button>

    <form [formGroup]="form" (ngSubmit)="findProductByCode(form.getRawValue().code)">
      <div>
        <mat-form-field appearance="fill">
          <mat-label>EAN Kód</mat-label>
          <input matInput formControlName="code" />
        </mat-form-field>

        <button mat-raised-button color="primary"
                [disabled]="form.invalid"
                >Přidat produkt</button>
      </div>
    </form>

    <ng-container *ngIf="productsInCart$ | async as productsInCart">
      <div>
        <div class="order-label">
          <h2>Objednávka - {{ (customer$ | async)?.name }}</h2>
          <button mat-icon-button
                  [attr.aria-label]="showBuyPrice ? 'Shovat nákupní cenu' : 'Zobrazit nákupní cenu'"
                  [title]="showBuyPrice ? 'Shovat nákupní cenu' : 'Zobrazit nákupní cenu'"
                  color="primary" (click)="showBuyPrice = !showBuyPrice">
            <mat-icon>{{showBuyPrice ? 'visibility' : 'visibility_off'}}}</mat-icon>
          </button>
        </div>

        <mat-form-field>
          <mat-label>Poznámka</mat-label>
          <textarea matInput [(ngModel)]="note"></textarea>
        </mat-form-field>

        <app-order-items-table
          [cartItems]="productsInCart"
          (editItem)="editProduct($event)"
          (removeItem)="removeProduct($event)"
          [showBuyPrice]="showBuyPrice"
        >
        </app-order-items-table>

      </div>


      <button mat-raised-button color="primary"
              [disabled]="productsInCart.length === 0"
              (click)="submitOrder()"
      >Pokračovat</button>
    </ng-container>
  </mat-card-content>
</mat-card>
