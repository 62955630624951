import { Component, OnInit } from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {AuthService, UserCredentials} from "../services/auth.service";
import {match, P} from "ts-pattern";
import {MainRoutes} from "../routes";

@Component({
  selector: 'autollshop-barcode-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent implements OnInit {
  form = this.fb.nonNullable.group({
    username: ["", {
      validators: [Validators.required]
    }],
    password: ['', [Validators.required, Validators.minLength(8)]]
  });
  public showPassword: boolean = false;

  loginStatus: string|null = null;

  constructor(private fb: FormBuilder, private router: Router, private authService: AuthService) {}

  ngOnInit(): void {}

  login() {
    if (this.form.valid) {
      const values = this.form.value as UserCredentials;
      this.authService.login(values).subscribe(result => {
        this.loginStatus = match(result)
          .with({ state: 'complete' }, (data) => 'Login success, redirecting...')
          .with({ state: 'error' }, (error) => error.error)
          .with({state: 'loading'}, () => 'Loading...')
          .with(P._, () => null)
          .exhaustive();

        if (result.state === "complete") {
          this.router.navigate([MainRoutes.SelectCustomer]);
        }
      });
    }
  }

  public togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }
}
