<h2 mat-dialog-title>Přidání zboží do košíku</h2>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <div class="order-label">
      <h3>Produkt: {{ product?.name }}</h3>
      <button mat-icon-button
              [attr.aria-label]="showBuyPrice ? 'Shovat nákupní cenu' : 'Zobrazit nákupní cenu'"
              [title]="showBuyPrice ? 'Shovat nákupní cenu' : 'Zobrazit nákupní cenu'"
              color="primary" (click)="showBuyPrice = !showBuyPrice">
        <mat-icon>{{showBuyPrice ? 'visibility' : 'visibility_off'}}}</mat-icon>
      </button>
    </div>
    <div class="details">
      <span>Skladem:</span><span>{{ product?.quantityAvailable }}</span>
      <ng-container *ngIf="showBuyPrice">
        <span>Nákupní cena:</span><span>{{ product?.price?.toFixed(2) }}</span>
        <span>Marže:</span><span>{{ margin }}</span>
      </ng-container>
      <span>Cena pro zákazníka</span><span>{{ product?.customerPrice?.toFixed(2) }}</span>
      <span>Základní cena</span><span>{{ product?.baseSellPrice?.toFixed(2) }}</span>
    </div>

    <mat-form-field appearance="fill">
      <mat-label>Počet kusů</mat-label>
      <input matInput type="number" formControlName="quantity" />
      <mat-error *ngIf="form?.get('quantity')?.errors?.['moreThenAllowed']">Není skladem dostatečné množství</mat-error>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Cena</mat-label>
      <input matInput formControlName="price"
             type="number"
             step=".01"
             [defaultValue]="product?.price?.toFixed(2)" />
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Poznámka</mat-label>
      <textarea matInput formControlName="note"></textarea>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-raised-button [mat-dialog-close]="null">Storno</button>
  <button mat-raised-button color="primary"
          [mat-dialog-close]="form.value"
          [disabled]="form.invalid"
          cdkFocusInitial>Přidat</button>
</mat-dialog-actions>
