import { ChangeDetectionStrategy, Component } from '@angular/core';
import {InfoDialogComponent} from "../info-dialog/info-dialog.component";

@Component({
  selector: 'autollshop-barcode-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmationDialogComponent extends InfoDialogComponent {

}
