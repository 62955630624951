import {Injectable} from "@angular/core";
import {BehaviorSubject, catchError, Observable, of, startWith, timer} from "rxjs";
import {AsyncData, LoadingState, wrapData, wrapError} from "../utils/async";
import {map} from "rxjs/operators";
import {ApplicationUser, AuthToken, User} from "../utils/user";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

export interface UserCredentials {
  username: string;
  password: string;
}

@Injectable({
  providedIn: "root"
})
export class AuthService {

  private _userSubject = new BehaviorSubject<ApplicationUser>(this.loadInitialUser());

  public isLoggedIn$ = this._userSubject.asObservable().pipe(
    map(user => {
      if (user.userType !== "Anonymous") {
        return user.authToken.expires_in > (Date.now() / 1000);
      }

      return false;
    }),
  );

  public authToken$: Observable<string|null> = this._userSubject.asObservable().pipe(
    map(user => {
      if (user.userType === "LoggedIn") {
        return user.authToken.access_token;
      } else {
        return null;
      }
    })
  );

  constructor(private http: HttpClient) {
  }

  private loadInitialUser() {
    const user = sessionStorage.getItem('user');
    return user != null ? JSON.parse(user) : User.anonymous();
  }

  public login(credentials: UserCredentials): Observable<AsyncData<UserCredentials, string>> {
    return this.http.post<AuthToken>(environment.server + '/login', credentials).pipe(
      map((response) => {
        if (credentials.username === 'error') {
          return wrapError('Invalid credentials');
        }

        const loggedUser = User.logged<any>({ ...credentials, authToken: response });
        sessionStorage.setItem('user', JSON.stringify(loggedUser));
        this._userSubject.next(loggedUser);

        return wrapData(credentials);
      }),
      catchError(() => of(wrapError('Invalid credentials'))),
      startWith(LoadingState),
    );
  }
}
