import {ChangeDetectionStrategy, Component, OnDestroy, OnInit} from '@angular/core';
import {OrderService} from "../services/order.service";
import {Router} from "@angular/router";
import {MainRoutes} from "../routes";
import {BehaviorSubject, combineLatest, Subject, takeUntil} from "rxjs";
import {filter, map} from "rxjs/operators";
import {MatDialog} from "@angular/material/dialog";
import {InfoDialogComponent, InfoDialogData} from "../info-dialog/info-dialog.component";

@Component({
  selector: 'autollshop-barcode-order-recapitulation',
  templateUrl: './order-recapitulation.component.html',
  styleUrls: ['./order-recapitulation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderRecapitulationComponent implements OnInit, OnDestroy {
  public Routes = MainRoutes;

  orderStatus$ = this.orderService.orderStatus$;
  destroy$ = new Subject();

  data$ = combineLatest([
    this.orderService.cart$,
    this.orderService.order$,
    this.orderService.customer$
  ]).pipe(map(([cart, order, customer]) => {
    return { cart, order, customer };
  }));

  _orderWaitingToBeSubmitted = new BehaviorSubject(false);

  disableSubmitButton$ = combineLatest([
    this.orderStatus$.pipe(map(x => x.loading)),
    this._orderWaitingToBeSubmitted.asObservable(),
  ]).pipe(map(([loading, waiting]) => loading || waiting));

  constructor(
    public orderService: OrderService,
    private router: Router,
    private dialogService: MatDialog,
  ) {}

  ngOnInit(): void {
    this.orderStatus$.pipe(
      takeUntil(this.destroy$),
      filter(x => x.state === 'complete')
    ).subscribe(_ => {
      this._orderWaitingToBeSubmitted.next(false);
      this.router.navigate([MainRoutes.OrderSubmitted]);
    });

    this.orderStatus$.pipe(
      takeUntil(this.destroy$),
      filter(x => x.state === 'error')
    ).subscribe(error => {
      if (error.state !== 'error') return;

      this._orderWaitingToBeSubmitted.next(false);

      const dialog = this.dialogService.open<InfoDialogComponent, InfoDialogData>(InfoDialogComponent, {
        data: {
          title: 'Chyba',
          content: `${error.error}\nObjednávka byla uložena do paměti.`
        }
      });

      dialog.afterClosed().subscribe(() => {
        this.orderService.selectCustomerAndNewOrder();
        this.router.navigate([MainRoutes.SelectCustomer])
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  submitOrder() {
    this._orderWaitingToBeSubmitted.next(true);
    this.orderService.submitOrder();
  }
}
