import {Injectable} from "@angular/core";
import {Product} from "./product.service";
import {BehaviorSubject, catchError, concatMap, Observable, of, startWith, timer} from "rxjs";
import {AsyncData, LoadingState, NotYetStartedState, wrapData, wrapError} from "../utils/async";
import {map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";
import {convertCsvToObject, convertOrderToCsv} from "../utils/csv";
import {environment} from "../../environments/environment";
import {AuthService} from "./auth.service";

export type LocalStorageOrder = Order & {
  __status__: string;
  __date__: Date;
}

export interface Order {
  __internal_id__?: number;

  id: string;
  shortId: string;
  date: Date;
  customer: Customer;
  products: ProductInCart[];
  note: string;
}

export interface ProductInCart {
  product: Product;
  quantity: number;
  price: number;
  note: string;
}

export interface Customer {
  id: string;
  name: string;
  ico: string;
  city: string;
  street: string;
  zip: string;
}

type csvHeaderValues =
  'cislo'|'nazev'|'kontakt'|'ico'|'dic'|'dmesto'|'dulice'
  |'dpsc'|'fmesto'|'fulice'|'fpsc'|'telefon'
  |'indivsleva'|'prodcenik'|'splatnost'|'www'
  |'poznamka'|'pobocka'|'dealer'|'trasa';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  private _customer = new BehaviorSubject<Customer|null>(null);
  public readonly customer$ = this._customer.asObservable();

  private _cart = new BehaviorSubject<ProductInCart[]>([]);
  public readonly cart$ = this._cart.asObservable();

  private _order = new BehaviorSubject<Order|null>(null);
  public readonly order$ = this._order.asObservable();

  private _orderStatus = new BehaviorSubject<AsyncData<any>>(NotYetStartedState);
  public orderStatus$ = this._orderStatus.asObservable();

  constructor(private http: HttpClient, private authService: AuthService) {

  }

  getCustomersFromCsv(): Observable<AsyncData<Customer[]>> {
    return this.authService.authToken$.pipe(
      concatMap(token => {
        return this.http.get(environment.server + '/customers', {
          responseType: 'text',
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
      }),
      map(res => {
        const csvArray: Customer[] = convertCsvToObject(res).map((item: Record<csvHeaderValues, string>) => ({
          id: item.cislo,
          name: item.nazev,
          ico: item.ico,
          city: item.fmesto,
          street: item.fulice,
          zip: item.fpsc
        }));

        return csvArray;
      }),
      map(result => wrapData(result)),
      startWith(LoadingState),
    );
  }

  getCustomers(): Observable<AsyncData<Customer[]>> {
    return this.getCustomersFromCsv();
  }

  selectCustomer(customer: Customer|null) {
    this._customer.next(customer);
  }

  addProduct(product: ProductInCart) {
    const cart = this._cart.getValue();

    const newCart = [ ...cart, product ];
    this._cart.next(newCart);
  }

  updateProduct(original: ProductInCart, updated: ProductInCart) {
    const cart = this._cart.getValue();
    const index = cart.indexOf(original);

    if (index !== -1) {
      const newCart = [ ...cart ];
      newCart.splice(index, 1, updated);
      this._cart.next(newCart);
    }
  }

  removeProduct(product: ProductInCart) {
    const cart = this._cart.getValue();

    const newCart = cart.filter(x => x !== product);
    this._cart.next(newCart);
  }

  setNote(note: string) {
    const value = this._order.getValue();

    if (value == null) {
      return;
    }

    const newValue = { ...value, note };
    this._order.next(newValue);
  }

  editOrder(order: Order) {
    if (order == null) {
      return;
    }

    this.selectCustomer(order.customer);
    this._cart.next(order.products);

    this._order.next(order);
  }

  submitOrder() {
    const value = this._order.getValue();

    if (value == null) {
      return;
    }

    const newValue = {
      ...value,
      id: '{{ORDER-ID}}',
      shortId: '{{ORDER-SHORTID}}',
      date: new Date(),
      customer: this._customer.getValue()!,
      products: this._cart.getValue() ?? [],
    };
    this._order.next(newValue);

    return this.sendOrderToServer(newValue);
  }

  sendOrderToServer(order: Order, saveToStorage: boolean = true) {
    return this.authService.authToken$.pipe(
      concatMap(token => {
        const data = convertOrderToCsv(order);

        if (saveToStorage) {
          this.saveOrderToLocalStorage(order);
        }

        return this.http.post(environment.server + '/orders', data, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'X-Order-Id': order?.__internal_id__?.toString() ?? '',
          }
        }).pipe(
          map(result => {
            this.setOrderStatusInLocalStorage(order, 'success');
            return wrapData({
              status: 'success',
              data: result
            });
          }),
          catchError(error => {
            this.setOrderStatusInLocalStorage(order, 'error');
            return of(wrapError('Selhalo odesílání dat na server'))
          }),
        );
      }),
      startWith(LoadingState),
    ).subscribe(status => {
      this._orderStatus.next(status);
    });
  }

  setOrderStatusInLocalStorage(order: Order, status: string) {
    const savedOrders = this.getOrdersFromLocalStorage();
    const localStorageOrder = savedOrders[order.date.getTime()] as LocalStorageOrder;
    localStorageOrder['__status__'] = status;
    localStorageOrder["__date__"] = new Date();

    localStorage.setItem('orders', JSON.stringify(savedOrders));
  }

  saveOrderToLocalStorage(order: Order) {
    const maxSavedOrders = 25;
    const savedOrders = this.getOrdersFromLocalStorage();

    const allSavedOrders = Object.keys(savedOrders).map(x => savedOrders[x]);
    allSavedOrders.sort((a, b) => (a?.date?.getTime() ?? 0) - (b?.date?.getTime() ?? 0));

    if (allSavedOrders.length >= maxSavedOrders) {
      const ordersToRemove = allSavedOrders.slice(0, allSavedOrders.length - maxSavedOrders + 1);
      ordersToRemove.forEach(x => delete savedOrders[x.date.getTime()]);
    }

    const id = order.date.getTime();
    savedOrders[id] = order;
    localStorage.setItem('orders', JSON.stringify(savedOrders));
  }

  getOrdersFromLocalStorage() {
    const savedOrders = JSON.parse(localStorage.getItem('orders') ?? "{}", (key, value) => {
      if (key === 'date') {
        return new Date(value);
      }

      return value;
    }) as Record<string, Order>;

    return savedOrders;
  }

  deleteOrderFromLocalStorage(order: Order) {
    const id = order.date.getTime();
    const savedOrders = this.getOrdersFromLocalStorage();

    if (savedOrders.hasOwnProperty(id)) {
      delete savedOrders[id];
    }

    localStorage.setItem('orders', JSON.stringify(savedOrders));
  }

  selectCustomerAndNewOrder() {
    this.selectCustomer(null);
    this.newOrder();
  }

  newOrder() {
    const timestamp = Math.floor(new Date().getTime() / 1000);

    this._order.next({
      __internal_id__: timestamp,
      id: '{{ORDER-ID}}',
      shortId: '{{ORDER-SHORTID}}',
      date: new Date(),
      customer: this._customer.getValue()!,
      products: [],
      note: '',
    });
    this._orderStatus.next(NotYetStartedState);
    this._cart.next([]);
  }
}

export function calculateOrderPrice(order: Order, withVat: boolean = false) {
  return calculateCartPrice(order.products, withVat);
}

export function calculateCartPrice(cartItems: ProductInCart[], withVat: boolean = false) {
  const items = cartItems ?? [];

  return items.reduce((sum, x) => {
    const price = calculateProductPrice(x);
    return sum + price;
  }, 0);
}

export function calculateProductPrice(productInCart: ProductInCart, withVat: boolean = false) {
  const priceWithoutVat = productInCart.price * productInCart.quantity;
  const vat = (productInCart.product.vat) / 100.0;
  const price = withVat ? priceWithoutVat * (1 + vat) : priceWithoutVat;

  return price;
}
