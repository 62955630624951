<table mat-table [dataSource]="orders">
  <!-- Name Column -->
  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef> ID </th>
    <td mat-cell *matCellDef="let element" [ngClass]="element.__status__">
      {{ element.customer.name }} - {{ element.date | date:'dd.MM. HH:mm' }}
    </td>
  </ng-container>

  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu"
              [matMenuTriggerData]="{ order: element }">
        <mat-icon>more_vert</mat-icon>
      </button>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  <tr class="mat-row" *matNoDataRow>
    <td class="mat-cell" [attr.colspan]="displayedColumns.length">
      Žádné uložené objednávky
    </td>
  </tr>
</table>

<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-order="order">
    <button mat-menu-item (click)="sendOrder(order)">
      <mat-icon>send</mat-icon>
      <span>Odeslat</span>
    </button>
    <button mat-menu-item (click)="editOrder(order)">
      <mat-icon>edit</mat-icon>
      <span>Upravit</span>
    </button>
    <button mat-menu-item (click)="deleteOrder(order)">
      <mat-icon>delete</mat-icon>
      <span>Odstranit</span>
    </button>
  </ng-template>
</mat-menu>
