import {catchError, Observable, of, startWith} from "rxjs";
import {filter, map} from "rxjs/operators";

// Ui stack:
/*
- blank
- loading
- partial
- error
- ideal
*/

export interface CommonData {
  state: string;
  loading: boolean;
  failed: boolean;
}

export interface NotYetStarted extends CommonData {
  state: 'not-started';
  loading: false;
  failed: false;
}

export interface LoadingData extends CommonData {
  state: 'loading';
  loading: true;
  failed: false;
}

export interface PartialData<T> extends CommonData {
  state: 'partial';
  loading: true;
  failed: false;
  data: T;
}

export interface CompleteData<T> extends CommonData {
  state: 'complete';
  loading: false;
  failed: false;
  data: T;
}

export interface ErrorData<E> extends CommonData {
  state: 'error';
  loading: false;
  failed: true;
  error: E;
}

export type AsyncData<T = any, E = string> = NotYetStarted | LoadingData | PartialData<T> | CompleteData<T> | ErrorData<E>;
export type AtLeastPartiallyResolvedAsyncData<T> = PartialData<T> | CompleteData<T>;

export const LoadingState: LoadingData = Object.freeze({ state: 'loading', loading: true, failed: false, data: null });
export const NotYetStartedState: NotYetStarted = Object.freeze({state: "not-started", loading: false, failed: false});

export function wrapData<T>(data: T): CompleteData<T> {
  return {
    state: 'complete',
    loading: false,
    failed: false,
    data
  };
}

export function wrapPartialData<T>(data: T): PartialData<T> {
  return {
    state: 'partial',
    loading: true,
    failed: false,
    data
  };
}

export function wrapError<E>(error: E): ErrorData<E> {
  return {
    state: 'error',
    loading: false,
    failed: true,
    error
  };
}

export function getAsyncData<T, E = string>(observable: Observable<T>): Observable<AsyncData<T, E>> {
  return observable.pipe(
    map(data => wrapData(data)),
    catchError(error => of(wrapError(error))),
    startWith(LoadingState)
  );
}


export function getAsyncDataOrDefault<T>(data: AsyncData<T>, def: T|null = null): T|null {
  return data.state === 'complete' || data.state === 'partial' ? data.data : def;
}

/***
 * Filters observable until it reaches 'partial' or 'complete' state,
 * then returns available data.
 */
export function getAtLeastPartiallyResolvedData() {
  return function<T>(source: Observable<AsyncData<T>>) {
    return source.pipe(
      filter(item => item.state === 'partial' || item.state === 'complete'),
      map(item => item as AtLeastPartiallyResolvedAsyncData<T>),
      map(item => item.data)
    );
  }
}

export function mapAsyncData<T, U>(data: AsyncData<T>, mapper: (item: T) => U): AsyncData<U> {
  return data.state === "complete" || data.state === 'partial' ? {
    ...data,
    data: mapper(data.data)
  } : data;
}


/**
 * Maps to partial or loading state based on previous state.
 *
 * If previous state was partial or complete, wraps to partial.
 * Otherwise wraps to loading.
 * @param data
 */
export function mapToLoadingOrPartial<T>(data: AsyncData<T>): AsyncData<T> {
  if (data.state === 'partial' || data.state === 'complete') {
    return wrapPartialData(data.data);
  }

  return LoadingState;
}
